import isEmpty from "is-empty";
import { useState, useContext, createContext, useEffect } from "react";
import SideNavigation from "./SideNavigation";
import AdminTopNavigation from "./AdminTopNavigation";
import { useSelector, useDispatch } from "react-redux";
import { PostAPI } from "../../../services/Service";
import { Link } from 'react-router-dom';
import NavigationMenu from "../../../_nav";
import Footer from "./Footer";
import { FaLess } from "react-icons/fa";

function Dashboard(props) {
  const detail = {
    todayRecharge: 2000,
    todayBillpayment: 5000,
    todayPencard: 6,
  };
  const [uDetails, setUdetails] = useState(detail);
  const [totalStudents, setTotalStudents] = useState(null);
  const [totalVideo, setTotalVideo] = useState(null);
  const [totalNotes, setTotalNotes] = useState(null);
  const [userGroup ,setUserGroup]=useState(false)

  let userDta =  JSON.parse(localStorage.getItem("userDta"));
   console.log(userDta)
   useEffect(() => {
    if (userDta?.user?.user_group === "Teacher") {
      setUserGroup(true);
    }
  }, [userDta]);
  

  const fetchTotalStudents = async () => {
    const studentData = await PostAPI("student_management/student_count", {});

    setTotalStudents(studentData.data);
  };

  useEffect(() => {
    fetchTotalStudents();
  }, []);

  const fetchTotalNotes = async () => {
    const notesData = await PostAPI("notes/notes_count", {});

    setTotalNotes(notesData.data);
  };

  useEffect(() => {
    fetchTotalNotes();
  }, []);

  const fetchTotalVideos = async () => {
    const videosData = await PostAPI("video/video_count", {});

    setTotalVideo(videosData.data);
  };

  useEffect(() => {
    fetchTotalVideos();
  }, []);

  return (
    <>
      <div class="content-wrapper">
        <div class="container d-flex  ">
       {
        userGroup &&
        <div class="col-lg-3 col-6">
        <div class="small-box bg-warning">
          <div class="inner">
            <h3>{totalStudents}</h3>
            <p className="text-bold">Total Student</p>
          </div>
          <div class="icon">
            <i class="ion ion-person-add"></i>
          </div>

          <Link to="/student-list" class="small-box-footer" >
            More info <i class="fas fa-arrow-circle-right"></i>
          </Link>
        </div>
      </div>

       }
          <div class="col-lg-3 col-6">
            <div class="small-box bg-success">
              <div class="inner">
                <h3>{totalNotes}</h3>
                <p className="text-bold">Total Notes</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
              <Link to="/notes" class="small-box-footer" >
                More info <i class="fas fa-arrow-circle-right"></i>
              </Link>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <div class="small-box bg-info">
              <div class="inner">
                <h3>{totalVideo}</h3>
                <p className="text-bold">Total Video</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
              <Link to="/videos" class="small-box-footer" >
                More info <i class="fas fa-arrow-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}

export default Dashboard;
