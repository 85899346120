import isEmpty from "is-empty";
import {useState,useContext,createContext, useEffect} from "react";

import {PostAPI} from "../../../../services/Service";
import Pagination from "../../Pagination";
import {PAGINATION_PER_PAGE} from "../../../../utils/Constants";
import Loader from "../../../assest/UI/Loader";
import {toast } from 'react-toastify';


import {
  useNavigate
} from "react-router-dom";

function UserList() {
  let navigate = useNavigate();

  const [items_perpage,seIitems_perpage] = useState(PAGINATION_PER_PAGE);
  const [current_page,setCurrent_page] = useState(1);
  const [totalPages,setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [userList,setUserList] = useState([]);

  const getUserList = async() => {
    setIsLoading(true);
    const useListData = await PostAPI("user_management/getmyuser",{items_perpage:items_perpage,current_page:current_page});

    if(useListData.response_code==200){
        setUserList(useListData.data.result);
        setTotalPage(useListData.data.total);
        setIsLoading(false);
    }else{
        toast.error(useListData.message);
        setIsLoading(false);

    }

  }

  useEffect(() => {
    getUserList();
  },[]);

  useEffect(()=> {
      getUserList();
  },[current_page])

  const pagechangeHandler = (offset) => {
    //debugger;
    //alert(offset)
    setCurrent_page(offset);
  }

  return (
    <div>

        <div class="content-wrapper">

            <main role="main" class="col-md-12 pt-3 px-4">
          <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 class="h2">User List</h1>
            <div class="btn-toolbar mb-2 mb-md-0">
              <div class="btn-group mr-2">
                <button class="btn btn-sm btn-outline-secondary">Share</button>
                <button class="btn btn-sm btn-outline-secondary">Export</button>
              </div>
              <button class="btn btn-sm btn-outline-secondary dropdown-toggle">
                <span data-feather="calendar"></span>
                This week
              </button>
            </div>
          </div>

          <div class="table-responsive">
           <button class="btn btn-outline-success float-right" onClick={()=> { navigate('/add-user'); }}>Add User</button> <br/>
          
           
           <table class="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>RMN Number</th>
                  <th>Email</th>
                  <th>City</th>
                  <th>Balance</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userList.length===0 && <tr ><td colspan="7">No Record Found</td></tr>}
                {userList.map((value,key) => {
                    return(
                      <tr>
                        <td>{key+1}</td>
                        <td>{value.id} {value.first_name} {value.last_name}</td>
                        <td>{value.username}</td>
                        <td>{value.email}</td>
                        <td>{value.city}</td>
                        <td>{value.wallet_balance}</td>
                        <td><button class="btn btn-outline-success" onClick={()=> navigate('/user/givefund/'+value.id,{state:value})}>Give Fund</button></td>
                      </tr>
                    )
                })}
              </tbody>
            </table>

            {isLoading && 
              <Loader/>
            }
                {
                  totalPages > 0 &&
                  <Pagination itemsPerPage={items_perpage} totalPage={totalPages} pagechangeHandler={pagechangeHandler} />
                }
            
          </div>
        </main>
      </div>
   
     
    </div>
  );
}

export default UserList;
