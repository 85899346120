import {
  ALLOW_PANCARD,
  ALLOW_PAYMENT_REQ,
  ALLOW_PAYOUT,
} from "./utils/Constants.js";

const NavigationMenu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "  ",
    urlAccess: ["Admin", "Retailer", "User", "Teacher", ""],
  },
];

/*if (ALLOW_RECHARGE_ONE) {
      NavigationMenu.push(
        {
          name:"Recharge 1",
          urlAccess:['Admin','Retailer','Distributor','Master Distributor'],
          icon:"fa fa-mobile",
          children:[
            {
              name:"Mobile",
              url:"/recharge",
              icon:"fa fa-mobile",
              urlAccess:['Admin','Retailer','Distributor','Master Distributor']
            },
            {
              name:"DTH Recharge",
              url:"recharge/dth",
              icon:"fa fa-mobile",
              urlAccess:['Admin','Retailer','Distributor','Master Distributor']
            }
            ,{
              name:"Recharge History",
              url:"/recharge/history",
              icon:"fa fa-history",
              urlAccess:['Admin','Retailer','Distributor','Master Distributor']
            }
          ]
        })
  }
*/

// NavigationMenu.push(
// {
//   name:"Enquery Form",
//   icon:"fa fa-history",
//   url:"/history",

//   urlAccess: ["Admin", "Retailer", "User", "Teacher", ""],
// })

NavigationMenu.push({
  name: "Student Managment",
  url: "/student-management",
  icon: "fa fa-calculator",
  urlAccess: ["Admin", "Retailer", "User", "Teacher", ""],
});

NavigationMenu.push({
  name: "Notes",

  url: "/notes",
  icon: "fa fa-calculator",
  urlAccess: ["Admin", "Retailer", "User", "Teacher", ""],
});
NavigationMenu.push({
  name: "Video",
  url: "/videos",
  icon: "fa fa-play-circle",
  urlAccess: ["Admin", "Retailer", "User", "Teacher", ""],
});

NavigationMenu.push({
  name: "Learning Video",
  url: "/student-video",
  icon: "fa fa-play-circle",
  urlAccess: ["Student"],
});

NavigationMenu.push({
  name: "Study Notes",
  url: "/study-notes",
  icon: "fa fa-play-circle",
  urlAccess: ["Student"],
});

NavigationMenu.push({
  name: "Support",
  url: "/support",
  icon: "fa fa-user-plus",
  urlAccess: ["Admin", "Retailer", "Student", "Teacher"],
});

NavigationMenu.push({
  name: "Enquiry",
  url: "/enquiry",
  icon: "fa fa-envelope",
  urlAccess: ["Admin", "Retailer", "Teacher"],
});
NavigationMenu.push({
  name: "Change Password",
  url: "/changepassword",
  icon: "fa fa-calculator",
  urlAccess: ["Admin", "Retailer", "Student", "Teacher", ""],
});
NavigationMenu.push({
  name: "My Profile",
  url: "/profile",
  icon: "fa fa-user",
  urlAccess: ["Student"],
});

export default NavigationMenu;
