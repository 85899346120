import isEmpty from "is-empty";
import { useState, useContext, createContext, useEffect } from "react";

import { PostAPI } from "../../../../services/Service";
import Pagination from "../../Pagination";
import { PAGINATION_PER_PAGE } from "../../../../utils/Constants";
import Loader from "../../../assest/UI/Loader";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

function StudentList() {
  let navigate = useNavigate();

  const [items_perpage, seIitems_perpage] = useState(PAGINATION_PER_PAGE);
  const [current_page, setCurrent_page] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [userList, setUserList] = useState([]);

  const getUserList = async () => {
    setIsLoading(true);
    const useListData = await PostAPI("student_management/get_my_students", {
      items_perpage: items_perpage,
      current_page: current_page,
    });

    if (useListData.response_code == 200) {
      setUserList(useListData.data.result);
      setTotalPage(useListData.data.total);
      setIsLoading(false);
    } else {
      toast.error(useListData.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getUserList();
  }, [current_page]);

  const pagechangeHandler = (offset) => {
    setCurrent_page(offset);
  };

  //delete Student
  const handleDelete = (e, a) => {
    e.preventDefault();

    const confirmVar = window.confirm("Are you sure you want to delete?");
    if (confirmVar) {
      deleteData(a);
    }
  };
  const deleteData = async (a) => {
    const ListDataDelete = await PostAPI("student_management/delete_student", {
      id: a,
    });
    if (ListDataDelete.response_code == 200) {
      toast(ListDataDelete.message);
      getUserList();
    } else {
      toast.error(ListDataDelete.message);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div>
      <div class="content-wrapper">
        <main role="main" class="col-md-12 pt-3 px-4">
          {/* <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <h1 class="h2">Student List</h1>
          </div>
          <button
            class="btn btn-outline-success float-right mr-4 "
            onClick={() => {
              navigate("/student-management");
            }}
          >
            Add Student
          </button>{" "}
          <br /> */}
          <div className="d-flex justify-content-between">
          <h1 class="h2">Student List</h1>
          <div>
          <button
            class="btn btn-outline-success mr-4 mt-2 mb-3"
            onClick={() => {
              navigate("/student-management");
            }}
          >
          Add Student
          </button>{" "}
        
          </div>
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>City</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userList.length === 0 && (
                  <tr>
                    <td colspan="7">No Record Found</td>
                  </tr>
                )}
                {userList.map((value, key) => {
                  return (
                    <tr>
                      <td>{value.id}</td>
                      <td>{value.username}</td>
                      <td>
                        {" "}
                        {value.first_name} {value.last_name}
                      </td>
                      <td>{value.email}</td>
                      <td>{value.phone}</td>
                      <td>{value.city}</td>
                      <td>
                        {value.active == 1 ? (
                          <span class="text-success">Active</span>
                        ) : (
                          "Deactive"
                        )}
                      </td>
                      <td>
                        <i
                          onClick={(e) => handleDelete(e, value.id)}
                          class="bi bi-trash-fill"
                        ></i>{" "}
                        <i
                          onClick={() => {
                            navigate(`/student-update/${value.id}`);
                          }}
                          className="bi bi-pencil-square ml-4"
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {isLoading && <Loader />}
            {totalPages > 0 && (
              <Pagination
                itemsPerPage={items_perpage}
                totalPage={totalPages}
                pagechangeHandler={pagechangeHandler}
              />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default StudentList;
