import React from "react";

const Profile = () => {
  return (
    <div className="content-wrapper">
        <main class="col-md-12 pt-3 px-4">
        <div>
            <h3>My Profile</h3>
        </div>
      <div className="row">
       
        <div className="col-md-4 text-center ">
          <div className="card p-3 shadow-sm border-0 rounded">
            <img
              src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Profile"
              className="rounded-circle mx-auto d-block border border-secondary"
              style={{ width: "150px", height: "150px" }}
            />
            <h4 className="mt-3 mb-1"> Shailedra Singh</h4>
            <p className="text-muted mb-1">johndoe45@gmail.com</p>
            <p className="text-muted">United States</p>
          </div>
        </div>

      
        <div className="col-md-8">
          <div className="card p-4 shadow-sm border-0 rounded">
            <div className="row mb-2 p-2" style={{ backgroundColor: "#f8f9fa" }}>
              <label className="col-5 text-secondary fw-bold">Name</label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  value=" Shailedra Singh"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-2 p-2" style={{ backgroundColor: "#ffffff" }}>
              <label className="col-5 text-secondary fw-bold">Class</label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  value="12th"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-2 p-2" style={{ backgroundColor: "#f8f9fa" }}>
              <label className="col-5 text-secondary fw-bold">Email</label>
              <div className="col-7">
                <input
                  type="email"
                  className="form-control"
                  value="johndoe45@gmail.com"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-2 p-2" style={{ backgroundColor: "#ffffff" }}>
              <label className="col-5 text-secondary fw-bold">Email Verification</label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control text-warning"
                  value="Pending"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-2 p-2" style={{ backgroundColor: "#f8f9fa" }}>
              <label className="col-5 text-secondary fw-bold">Contact</label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  value="+987 6789 9876 456"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-2 p-2" style={{ backgroundColor: "#ffffff" }}>
              <label className="col-5 text-secondary fw-bold">Mobile Verification</label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control text-success"
                  value="Active"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-2 p-2" style={{ backgroundColor: "#f8f9fa" }}>
              <label className="col-5 text-secondary fw-bold">Status</label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control text-success"
                  value="Active"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
    </div>
  );
};

export default Profile;