import React from "react";

/**
 * @author
 * @function Loader
 **/

const Loader = (props) => {
  return (
    <>
     <div className="loader-body col-sm-12">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
    </>
  );
};

export default Loader;
