import React, { useState } from "react";
import VideoList from "./VideoList";
import axios from "axios";
import { PostAPI, PostAPIWithImageUpload } from "../../../../services/Service";
import { toast } from "react-toastify";

const classArray = [
  { class: "1st" },
  { class: "2nd" },
  { class: "3rd" },
  { class: "4th" },
  { class: "5th" },
  { class: "6th" },
  { class: "7th" },
  { class: "8th" },
  { class: "9th" },
  { class: "10th" },
  { class: "11th" },
  { class: "12th" },
  { class: "1st year" },
  { class: "2nd year" },
  { class: "3rd year" },
];
const paternArray = [{ patern: "CBSE" }, { patern: "M.P Board" }];

const subjectArray = [
  { sub: "Hindi" },
  { sub: "English" },
  { sub: "Maths" },
  { sub: "Physics" },
  { sub: "Chemiestry" },
  { sub: "Bio" },
  { sub: "Account" },
];

const lessonArray = [
  { lesson: "1st" },
  { lesson: "2nd" },
  { lesson: "3rd" },
  { lesson: "4th" },
  { lesson: "5th" },
  { lesson: "6th" },
  { lesson: "7th" },
  { lesson: "8th" },
  { lesson: "9th" },
  { lesson: "10th" },
  { lesson: "11th" },
  { lesson: "12th" },
  { lesson: "13th" },
  { lesson: "14th" },
];
const videoData = {
  subject: "",
  class: "",
  title: "",
  pattern: "",
  description: "",
  lession: "",
  video: "",
};

function Videos() {
  const [stuclass, setStuClass] = useState(classArray);
  const [patern, setPatern] = useState(paternArray);
  const [sub, setSub] = useState(subjectArray);
  const [lesson, setLesson] = useState(lessonArray);
  const [inputFormData, setInputFormData] = useState(videoData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFormData({ ...inputFormData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const videos = e.target.video.files[0];
    const formData = new FormData();
    formData.append("video", videos);
    formData.append("subject", inputFormData.subject);
    formData.append("class", inputFormData.class);
    formData.append("pattern", inputFormData.pattern);
    formData.append("title", inputFormData.title);
    formData.append("description", inputFormData.description);
    formData.append("lession", inputFormData.lession);

    const response = await PostAPIWithImageUpload(
      "video/add_videonotes",
      formData,
      "multipart/form-data"
    );

    console.log("Notes added Successfully", response.data);

    if (response.response_code == "200") {
      toast.success(response.message);
      setInputFormData(videoData);
    } else if (response.response_code == "500") {
      toast.error(response.message);
    }

    setIsLoading(false);
  };

  // const validateForm = (data) => {
  //   let errors = {};

  //   if (!data.class.trim()) {
  //     errors.class = "Select a class";
  //   }

  //   if (!data.patern) {
  //     errors.patern = "Select a pattern";
  //   }

  //   if (!data.sub) {
  //     errors.sub = "Select a subject";
  //   }

  //   if (!data.lesson) {
  //     errors.lesson = "Select a lesson";
  //   }

  //   if (!data.title.trim()) {
  //     errors.title = "Title is required";
  //   }

  //   if (!data.video) {
  //     errors.video = "Video is required";
  //   }

  //   if (!data.description.trim()) {
  //     errors.description = "Description is required";
  //   }

  //   return errors;
  // };
  return (
    <form onSubmit={handleSubmit} action="" encType="multipart/form-data">
      <>
        <div class="content-wrapper">
          <main role="main" class="col-md-12 pt-3 px-4">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
              <h1 class="h2"> Videos</h1>
            </div>

            <div class="row">
              <div class="col-md-6 pt-3 px-4">
                <select
                  id="class"
                  name="class"
                  value={inputFormData.class}
                  onChange={handleChange}
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select The Class</option>
                  {stuclass.map((item) => (
                    <option value={item.class}>{item.class}</option>
                  ))}
                </select>
                {errors.class && (
                  <div style={{ color: "red" }} className="error">
                    {errors.class}
                  </div>
                )}
              </div>

              <div class="col-md-6 pt-3 px-4">
                <select
                  id="pattern"
                  name="pattern"
                  value={inputFormData.pattern}
                  onChange={handleChange}
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select The Pattern</option>
                  {patern.map((item) => (
                    <option value={item.patern}>{item.patern}</option>
                  ))}
                </select>
                {errors.patern && (
                  <div style={{ color: "red" }} className="error">
                    {errors.patern}
                  </div>
                )}
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 pt-3 px-4">
                <select
                  id="subject"
                  name="subject"
                  value={inputFormData.subject}
                  onChange={handleChange}
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select The Subject</option>
                  {sub.map((item) => (
                    <option value={item.sub}>{item.sub}</option>
                  ))}
                </select>
                {errors.sub && (
                  <div style={{ color: "red" }} className="error">
                    {errors.sub}
                  </div>
                )}
              </div>

              <div class="col-md-6 pt-3 px-4">
                <select
                  id="lession"
                  name="lession"
                  value={inputFormData.lession}
                  onChange={handleChange}
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select The Lesson</option>
                  {lesson.map((item) => (
                    <option value={item.lesson}>{item.lesson}</option>
                  ))}
                </select>
                {errors.lesson && (
                  <div style={{ color: "red" }} className="error">
                    {errors.lesson}
                  </div>
                )}
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 pt-3 px-4">
                <input
                  id="title"
                  name="title"
                  value={inputFormData.title}
                  onChange={handleChange}
                  type="text"
                  class="form-control"
                  placeholder="Title"
                  aria-label="Server"
                />
              </div>
              {errors.title && (
                <div style={{ color: "red" }} className="error">
                  {errors.title}
                </div>
              )}

              <div class="col-md-6 pt-3 px-4">
                <input
                  id="video"
                  name="video"
                  value={inputFormData.video}
                  onChange={handleChange}
                  type="file"
                  class="form-control"
                  placeholder=""
                  aria-label="Server"
                />
              </div>
              {errors.video && (
                <div style={{ color: "red" }} className="error">
                  {errors.video}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-11  my-3 mx-2 ">
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={inputFormData.description}
                  onChange={handleChange}
                  rows="3"
                  style={{ marginLeft: "5px" }}
                  placeholder="Enter description"
                ></textarea>
                {errors.description && (
                  <div style={{ color: "red" }} className="error">
                    {errors.description}
                  </div>
                )}
              </div>
            </div>

            <div class="mx-3 my-2">
              <button
                type="submit"
                style={{marginBottom:"10px"}}
                class="btn btn-outline-success"
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
            <VideoList></VideoList>
          </main>
        </div>
      </>
    </form>
  );
}

export default Videos;
