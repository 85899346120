import React, { useEffect, useState } from "react";
import { PostAPI } from "../../../../services/Service";
import { API_BASE_URL } from "./../../../../utils/config";
import Pagination from "../../Pagination";
import { PAGINATION_PER_PAGE } from "../../../../utils/Constants";
import Loader from "../../../assest/UI/Loader";


// Default Img
const DEFAULT_IMAGE_URL =
  "https://images.pexels.com/photos/1006293/pexels-photo-1006293.jpeg?auto=compress&cs=tinysrgb&w=600";

const StudentNotes = () => {
  const [notes, setNotes] = useState([]);
  const [current_page, setCurrent_page] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [items_perpage, setItems_perpage] = useState(PAGINATION_PER_PAGE);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNotes = async () => {
    setIsLoading(true)
    const response = await PostAPI("notes/notes_list", {
      items_perpage: items_perpage,
      current_page: current_page,
    });
    setNotes(response.data.result);
    setTotalPages(response.data.total);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [current_page]);

  const pagechangeHandler = (offset) => {
    setCurrent_page(offset);
  };

  return (
    <div className=" content-wrapper px-4">
      <h2>Study Notes</h2>
      {isLoading && 
                <Loader/>
              }
      <div className="row  ">
        {notes.map((items) => (
          <div className="col-md-2"
          //  style={{ marginLeft: '1.5rem'}}
            key={items.id}>
            <div
              className="card mt-4 student-note-card"
              // style={{ width: "11rem", height: "13rem" }} // Decreased size
            >
              <div className="card-body text-center">
                <p className="card-text fw-bold" 
                style={{ fontSize: "12px", textalign:"center"}}>
                  {/* {items.notes_title} */}
                  Title
                </p>
                <p className="card-text fw-bold" style={{ fontSize: "12px" }}>
                  {/* {items.notes_description} */}
                  Description
                </p>
              </div>
              <center><a
                href={
                  items.notes_img
                    ? `${API_BASE_URL}/images/${items.notes_img}`
                    : DEFAULT_IMAGE_URL
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="card-img-top"
                  style={{
                    width: "7rem", // Adjusted image width
                    height: "5rem", // Adjusted image height
                    marginTop: "0.5rem",
                    // marginLeft: "1.5rem"
                  }}
                  src={"img/pdf.png"}
                  alt="Card image cap"
                />
              </a></center>

              <div className="card-body text-center row">
                <button
                  type="button"
                  className="btn btn-primary col mx-1"
                  style={{ fontSize: "10px", padding: "4px 6px" }} // Adjusted button size
                >
                  View
                </button>
                <button
                  type="button"
                  className="btn btn-warning col mx-1"
                  style={{ fontSize: "10px", padding: "4px 6px" }} // Adjusted button size
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <Pagination
          currentPage={current_page}
          totalPages={totalPages}
          itemsPerPage={items_perpage}
          pagechangeHandler={pagechangeHandler}
        />
      </div>
    </div>
  );
};

export default StudentNotes;
