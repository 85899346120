import isEmpty from "is-empty";
import { useState, useContext, createContext, useEffect } from "react";

import { PostAPI } from "../../../../services/Service";
import Loader from "../../../assest/UI/Loader";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

const classArray = [
  { class: "1st" },
  { class: "2nd" },
  { class: "3rd" },
  { class: "4th" },
  { class: "5th" },
  { class: "6th" },
  { class: "7th" },
  { class: "8th" },
  { class: "9th" },
  { class: "10th" },
  { class: "11th" },
  { class: "12th" },
  { class: "1st year" },
  { class: "2nd year" },
  { class: "3rd year" },
];
const paternArray = [{ patern: "CBSE" }, { patern: "M.P Board" }];

const userDefaultData = {
  username: "",
  password: "",
  first_name: "",
  last_name: "",
  father_name: "",
  email: "",
  gender: "",
  phone: "",
  date: "",
  dob: "",
  state: "",
  city: "",
  pincode: "",
  address: "",
};

export default function StudentManagement() {
  let navigate = useNavigate();
  const [stuclass, setStuClass] = useState(classArray);
  const [patern, setPatern] = useState(paternArray);
  const [errorMessage, setErrorMessage] = useState("");
  const [formfilled, setformfilled] = useState(true);
  const [rechargeData, setRechargeData] = useState(userDefaultData);
  const [operator, setOperator] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOperatorList = async () => {
    const operator_list = await PostAPI("recharge/getALLOpeartor", {
      operator_plan: "prepaid",
    });
    setOperator(operator_list.data);
  };

  useEffect(() => {
    getOperatorList();
  }, []);

  const inputHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setRechargeData((prev) => ({ ...prev, [name]: value }));

    if (Object.getOwnPropertyNames(rechargeData).length > 2) {
      setformfilled(false);
    }
  };

  const inputHandlerDate = (e) => {
    e.preventDefault();
    const Dob = e.target.value;
    setRechargeData({ ...rechargeData, dob: Dob });
  };

  const submitHandler = async (e) => {
    setformfilled(true);
    e.preventDefault();
    setIsLoading(true);
    const rechargeResponce = await PostAPI(
      "student_management/create_student",
      {
        ...rechargeData,
      }
    );
    //console.log(rechargeResponce);
    if (rechargeResponce.response_code == 200) {
      toast(rechargeResponce.message);
      setformfilled(false);
      setRechargeData(userDefaultData);
      setIsLoading(false);
    }
    if (rechargeResponce.response_code == 500) {
      toast.error(rechargeResponce.message);
      setformfilled(false);
      setIsLoading(false);
    }
  };

  //delete Student

  return (
    <div>
      <div class="content-wrapper">
        <main role="main" class="col-md-12 pt-3 px-4">
          {/* {JSON.stringify(userDefaultData)} */}
          <div className="d-flex justify-content-between">
          <h3> Add Student </h3>
          <div>
          <button
            class="btn btn-outline-success mr-4 mt-3"
            onClick={() => {
              navigate("/student-list");
            }}
          >
            Student List
          </button>{" "}
        
          </div>
          </div>
          <form onSubmit={submitHandler}>
            <div class="row col-sm-12 col-xs-12 col-lg-12 mt-2">
              <div class="form-group col-sm-6 col-lg-3 ">
                <label for="amount">First Name</label>

                <input
                  type="text"
                  class="form-control"
                  id="first_name"
                  name="first_name"
                  value={rechargeData.first_name}
                  onChange={inputHandler}
                  placeholder="First Name"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3">
                <label for="amount">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="last_name"
                  name="last_name"
                  value={rechargeData.last_name}
                  onChange={inputHandler}
                  placeholder="Last Name"
                />
              </div>
              <div className="form-group col-sm-6 col-lg-3 ">
                <label htmlFor="fatherName">Father's Name :</label>
                <input
                  type="text"
                  className="form-control"
                  id="father_name"
                  name="father_name"
                  value={rechargeData.father_name}
                  onChange={inputHandler}
                  placeholder="Enter Father Name"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3">
                <label for="amount">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  name="email"
                  value={rechargeData.email}
                  onChange={inputHandler}
                  placeholder="Email"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3 col-12">
                <label>Phone</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  name="phone"
                  value={rechargeData.phone}
                  onChange={inputHandler}
                  placeholder="Phone"
                />
              </div>

              <div className="form-group col-sm-6 col-lg-3 col-12">
                <label htmlFor="gender">Gender :</label>
                <div>
                  <label className="mt-2" htmlFor="male">
                    Male{" "}
                  </label>
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    className="ml-2"
                    style={{ width: "20px", height: "15px" }}
                    checked={rechargeData.gender === "male"}
                    onChange={inputHandler}
                  />
                  <label className="mt-2 ml-3" htmlFor="female">
                    Female{" "}
                  </label>
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    className="ml-2"
                    style={{ width: "20px", height: "15px" }}
                    checked={rechargeData.gender === "female"}
                    onChange={inputHandler}
                  />
                </div>
              </div>

              <div className="form-group col-sm-6 col-lg-3 ">
                <label className="ml-1" htmlFor="dob">
                  DOB :
                </label>
                <br></br>

                <input
                  type="date"
                  id="dob"
                  name="dob"
                  style={{ width: "220px" }}
                  className="form-control"
                  value={rechargeData.dob}
                  onChange={inputHandler}
                ></input>
              </div>

              <div class="form-group col-sm-6 col-lg-3">
                <label>State</label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  name="state"
                  value={rechargeData.state}
                  onChange={inputHandler}
                  placeholder="State"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3 ">
                <label>City</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  name="city"
                  value={rechargeData.city}
                  onChange={inputHandler}
                  placeholder="City"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3 ">
                <label>PinCode</label>
                <input
                  type="text"
                  class="form-control"
                  id="pincode"
                  name="pincode"
                  value={rechargeData.pincode}
                  onChange={inputHandler}
                  placeholder="Pincode"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-6  col-lg-3 ">
                <label>Address</label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  name="address"
                  value={rechargeData.address}
                  onChange={inputHandler}
                  placeholder="Address"
                />
              </div>
              <div class="form-group col-sm-6 col-lg-3 ">
                <label>Username</label>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  name="username"
                  value={rechargeData.username}
                  onChange={inputHandler}
                  placeholder="Username"
                />
              </div>
              <div class="form-group col-sm-6 col-lg-3 ">
                <label>Password</label>
                <input
                  type="text"
                  class="form-control"
                  id="password"
                  name="password"
                  value={rechargeData.password}
                  onChange={inputHandler}
                  placeholder="Password"
                />
              </div>
            <div className="col-sm-6 col-lg-4 ">
            <label>Class</label>
              <select
                id="class"
                name="class"
                value={rechargeData.class}
                onChange={inputHandler}
                className="form-select"
                aria-label="      Default select example"
              >
                <option value="">Select The Class</option>
                {stuclass.map((item, index) => (
                  <option key={index} value={item.class}>
                    {item.class}
                  </option>
                ))}
              </select>

                </div>

            <div className="col-sm-6 col-lg-4">
            <label>Pattern</label>
              <select
                id="pattern"
                name="pattern"
                value={rechargeData.pattern}
                onChange={inputHandler}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="">Select The Pattern</option>
                {patern.map((item, index) => (
                  <option key={index} value={item.pattern}>
                    {item.patern}
                  </option>
                ))}
              </select>
             
            </div>
          </div>

              {isLoading && <Loader />}

              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              <button
                class="btn btn-outline-success ml-2"
                type="submit"
                disabled={formfilled}
              >
                Submit
              </button>
          </form>
        </main>
      </div>
    </div>
  );
}
