import React, { useState, useEffect } from "react";
import { PostAPI } from "../../../../services/Service";
import Pagination from "../../Pagination";
import { PAGINATION_PER_PAGE } from "../../../../utils/Constants";
import { toast } from "react-toastify";
import Loader from "../../../assest/UI/Loader";
import { API_BASE_URL } from "./../../../../utils/config";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";

const NotesList = () => {
  const [items_perpage, setItems_perpage] = useState(PAGINATION_PER_PAGE);
  const [current_page, setCurrent_page] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [render, setRender] = useState(false);

  const navigate = useNavigate();

  const getNoteList = async () => {
    setIsLoading(true);
    const notesListData = await PostAPI("notes/notes_list", {
      items_perpage: items_perpage,
      current_page: current_page,
    });

    if (notesListData.response_code === 200) {
      setNotesList(notesListData.data.result);
      setTotalPage(notesListData.data.total);
      setIsLoading(false);
    } else {
      toast.error(notesListData.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNoteList();
  }, []);

  useEffect(() => {
    getNoteList();
    setRender(false);
  }, [current_page],render);

  const pagechangeHandler = (offset) => {
    setCurrent_page(offset);
  };

  //delete Notes

  const deleteNotes = async (e, id) => {
    e.preventDefault();
    const deleteId = id;
    const confirmVar = window.confirm("Are you sure you want to delete?");
    if (confirmVar) {
      const deletenotesData = await PostAPI("notes/delete_notes", {
        id: deleteId,
      });
      if (deletenotesData.response_code == "200") {
        toast.success("Data deleted Successfully");
        setRender(prevRender => !prevRender); 
       
      } else if (deletenotesData.response_code == "500") {
        toast.error("data not deleted");
      }
      setRender(true);
    }
  };

  useEffect(() => {
    getNoteList();
  }, [render]);

  return (
    <div>
      <h3 style={{ margin: "10px" }}>Notes List</h3>
      <table
        style={{ margin: "13px" }}
        className="table table-striped table-bordered"
      >
        <thead>
          <tr>
            <th>#</th>
            <td className="text-dark">Class Name</td>
            <td className="text-dark">Pattern</td>
            <td className="text-dark">Subject</td>
            <td style={{ width: "300px" }} className="text-dark">
              Title
            </td>
            <td className="text-dark">Image</td>
            <td className="text-dark">Action</td>
          </tr>
        </thead>
        <tbody>
          {notesList.length === 0 && (
            <tr>
              <td colSpan="5">No Record Found</td>
            </tr>
          )}
          {notesList.map((value, key) => (
            <tr>
              <td>{value.id}</td>
              <td>{value.class}</td>
              <td>{value.notes_pattern}</td>
              <td>{value.subject}</td>
              <td>{value.notes_title}</td>
              <td>
                <a
                  href={`${API_BASE_URL}/images/${value.notes_img}`}
                  target="_blank"
                >
                  <img
                    src={`${API_BASE_URL}/images/${value.notes_img}`}
                    alt={`Image for ${value.className}`}
                    style={{ width: "60px", height: "50px", cursor: "pointer" }}
                  />
                </a>
              </td>
              <td>
                <i
                  onClick={(e) => deleteNotes(e, value.id)}
                  class="bi bi-trash-fill"
                ></i>
                <i
                  onClick={() => {
                    navigate(`/update-notes/${value.id}`);
                  }}
                  class="bi bi-pencil-square ml-5"
                >
                  {" "}
                </i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <Loader />}
      {totalPages > 0 && (
        <Pagination
          itemsPerPage={items_perpage}
          totalPage={totalPages}
          pagechangeHandler={pagechangeHandler}
        />
      )}
    </div>
  );
};

export default NotesList;
