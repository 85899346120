import isEmpty from "is-empty";
import {useState,useContext,createContext, useEffect} from "react";

import {PostAPI} from "../../../../services/Service";
import Loader from "../../../assest/UI/Loader";
import {toast } from 'react-toastify';

import {
    useNavigate
  } from "react-router-dom";

const userDefaultData = {
    first_name:"",
    last_name:"",
    email:"",
    phone:"",
    state:"",
    city:"",
    pincode:"",
    address:"",
    adhar:"",
    pan_number:""
}

export default function CreateNewUser() {
    let navigate = useNavigate();

    const [errorMessage,setErrorMessage] = useState("");
    const [formfilled,setformfilled] = useState(true);
    const [rechargeData,setRechargeData] = useState(userDefaultData);
    const [operator,setOperator] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getOperatorList = async() => {
        const operator_list = await PostAPI("recharge/getALLOpeartor",{operator_plan:"prepaid"});
        setOperator(operator_list.data);
    }

    useEffect(()=>{
        getOperatorList();       
    },[])


    const inputHandler = (e) => {
        const {name,value} = e.target;

        setRechargeData((prev) => ({...prev,[name]:value}));
        
        if(Object.getOwnPropertyNames(rechargeData).length>2){
            setformfilled(false);
        }
    }

    const submitHandler = async(e) => {

        setformfilled(true);
        e.preventDefault();
        setIsLoading(true);
        const rechargeResponce = await PostAPI("user_management/createUser",{...rechargeData});
        //console.log(rechargeResponce);
        if(rechargeResponce.response_code==200){
            toast(rechargeResponce.message);
            setformfilled(false);
            setRechargeData(userDefaultData);
            setIsLoading(false);
        }
        if(rechargeResponce.response_code==500){
            toast.error(rechargeResponce.message);
            setformfilled(false);
            setIsLoading(false);
        }
    }

  return (
    <div>



     

            <div class="content-wrapper">

            <main role="main" class="col-md-12 pt-3 px-4">
            {/*JSON.stringify(operator)*/}
            <h3>Add New User</h3>
            <button class="btn btn-outline-success float-right" onClick={()=> { navigate('/userlist'); }}>Users List</button> <br/>
                <form onSubmit={submitHandler}>
                    
                <div class="row col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group col-sm-6 col-lg-3">
                        <label for="amount">First Name</label>
                    
                    <input type="text" 
                        class="form-control" 
                        id="first_name"
                        name="first_name" 
                        value={rechargeData.first_name}
                        onChange={inputHandler}
                        placeholder="First Name"/>
                    </div>

                    <div class="form-group col-sm-6 col-lg-3">
                        <label for="amount">Last Name</label>
                        <input type="text" 
                        class="form-control" 
                        id="last_name"
                        name="last_name"
                        value={rechargeData.last_name}
                        onChange={inputHandler}
                        placeholder="Last Name"/>
                    </div>

                    <div class="form-group col-sm-6 col-lg-3">
                        <label for="amount">Email</label>
                        <input type="text" 
                        class="form-control" 
                        id="email"
                        name="email"
                        value={rechargeData.email}
                        onChange={inputHandler}
                        placeholder="Email"/>
                    </div>

                    <div class="form-group col-sm-6 col-lg-3 col-12">
                        <label >Phone</label>
                        <input type="text" 
                        class="form-control" 
                        id="phone"
                        name="phone"
                        value={rechargeData.phone}
                        onChange={inputHandler}
                        placeholder="Phone"/>
                    </div>

                    <div class="form-group col-sm-6 col-lg-3">
                        <label>State</label>
                        <input type="text" 
                        class="form-control" 
                        id="state"
                        name="state"
                        value={rechargeData.state}
                        onChange={inputHandler}
                        placeholder="State"/>
                    </div>

                    <div class="form-group col-sm-6 col-lg-3 ">
                        <label>City</label>
                        <input type="text" 
                        class="form-control" 
                        id="city"
                        name="city"
                        value={rechargeData.city}
                        onChange={inputHandler}
                        placeholder="City"/>
                    </div>

                    <div class="form-group col-sm-6 col-lg-3 ">
                        <label>PinCode</label>
                        <input type="text" 
                        class="form-control" 
                        id="pincode"
                        name="pincode"
                        value={rechargeData.pincode}
                        onChange={inputHandler}
                        placeholder="Pincode"/>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6  col-lg-3 ">
                        <label>Address</label>
                        <input type="text" 
                        class="form-control" 
                        id="address"
                        name="address"
                        value={rechargeData.address}
                        onChange={inputHandler}
                        placeholder="Address"/>
                    </div>
                    <div class="form-group col-sm-6 col-lg-3 ">
                        <label>Aadhar</label>
                        <input type="text" 
                        class="form-control" 
                        id="adhar"
                        name="adhar"
                        value={rechargeData.adhar}
                        onChange={inputHandler}
                        placeholder="Adhar"/>
                    </div>
                    <div class="form-group col-sm-6 col-lg-3 ">
                        <label>Pan Number</label>
                        <input type="text" 
                        class="form-control" 
                        id="pan_number"
                        name="pan_number"
                        value={rechargeData.pan_number}
                        onChange={inputHandler}
                        placeholder="Pan Number"/>
                    </div>

                    {isLoading && 
                        <Loader/>
                        }

                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    <button
                    class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
                    type="submit"
                    disabled={formfilled}
                    >
                    Submit
                    </button>
                </div>
            </form>
        </main>
      </div>

     
    </div>
  );
}
