import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Login from '../Login'
import {toast } from 'react-toastify';
import {ForgotPasswordapi}  from "../../../services/Service" ;

const ForgotPassword = () => {
    const [mobnumber , setMobnumber] = useState('');
    const [isforgot , setIsForgot] = useState(false);

    const handleChange = (e) => {
        setMobnumber((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleClick = async(e) => {
        e.preventDefault();
        // setIsForgot(true);

        const res = await ForgotPasswordapi(mobnumber);
        
        if(res.response_code === 200){
            toast(res.message);
           
        }

        if(res.response_code === 500){
           toast(res.message);
            
        }
    }
  return (
    <div class="container-fluid">
    <div class="row">
    <div class="col-md-4 col-lg-4 px-4 mx-auto">
        <h3 style={{marginTop:50}}>Forgot Password</h3>
    <div style={{marginTop:30}} class="form-group position-relative has-icon-left mb-4">
       
    <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Email"
                name="email"
                 value={mobnumber.email}
                onChange={handleChange}
              />
              {/* <span className="text-danger">{errors.mobile}</span> */}
             
            </div>
           {/* <button
              class="btn btn-primary p-2"
              type="submit"
              onClick={handleClick}
              disabled={isforgot}
             
            >
              Forgot Password
            </button>
            
            <Link to='/login'>
            <h5>login</h5>
            </Link> */}
             <div className="d-flex justify-content-between ">
            <button
              className="btn btn-primary p-2"
              type="submit"
              onClick={handleClick}
              disabled={isforgot}
            >
              Forgot Password
            </button>
            <Link to='/login'>
              <h5 className='mt-3 mr-2'>Login</h5>
            </Link>
          </div>
            
           

            </div>
            </div>
            </div>
  )
}

export default ForgotPassword