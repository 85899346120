import isEmpty from "is-empty";
import { useState, useContext, createContext, useEffect } from "react";

import { PostAPI } from "../../../../services/Service";
import Loader from "../../../assest/UI/Loader";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Pagination from "../../Pagination";
import { PAGINATION_PER_PAGE } from "../../../../utils/Constants";
import { useNavigate } from "react-router-dom";

const studentDefaultData = {
  first_name: "",
  last_name: "",
  phone: "",
  state: "",
  city: "",
  pincode: "",
  address: "",
};
const classArray = [
  { class: "1st" },
  { class: "2nd" },
  { class: "3rd" },
  { class: "4th" },
  { class: "5th" },
  { class: "6th" },
  { class: "7th" },
  { class: "8th" },
  { class: "9th" },
  { class: "10th" },
  { class: "11th" },
  { class: "12th" },
  { class: "1st year" },
  { class: "2nd year" },
  { class: "3rd year" },
];
const paternArray = [{ patern: "CBSE" }, { patern: "M.P Board" }];

export default function UpdateStudent() {
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [formfilled, setformfilled] = useState(true);
  const [rechargeData, setRechargeData] = useState(studentDefaultData);
  const [operator, setOperator] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [items_perpage, seIitems_perpage] = useState(PAGINATION_PER_PAGE);
  const [current_page, setCurrent_page] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [inputData, setInputData] = useState({});
  const [yourId, setYourId] = useState(id);
  const [stuclass, setStuClass] = useState(classArray);
  const [patern, setPatern] = useState(paternArray);

  const navigate = useNavigate();

  //Show data in input fields
  const getDataById = async () => {
    debugger;
    setIsLoading(true);
    const useListData = await PostAPI("student_management/get_student_by_id", {
      id: yourId,
    });

    if (useListData.response_code == "200") {
      setRechargeData(useListData.data);
      console.log(rechargeData.id);
      setIsLoading(false);
    } else {
      toast.error(useListData.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataById();
  }, []);

  useEffect(() => {
    getDataById();
  }, [yourId]);

  const inputHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setRechargeData((prev) => ({ ...prev, [name]: value }));

    if (Object.getOwnPropertyNames(rechargeData).length > 2) {
      setformfilled(false);
    }
  };

  const submitHandler = async (e) => {
    debugger;
    setformfilled(true);
    e.preventDefault();
    setIsLoading(true);
    const rechargeResponce = await PostAPI(
      "student_management/update_student",
      {
        ...rechargeData,
        id: yourId,
      }
    );
    if (rechargeResponce.response_code == "200") {
      toast(rechargeResponce.message);
      setformfilled(false);
      setIsLoading(false);
    }
    if (rechargeResponce.response_code == "500") {
      toast.error(rechargeResponce.message);
      setformfilled(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div class="content-wrapper">
        <main role="main" class="col-md-12 pt-3 px-4">
          {/*JSON.stringify(operator)*/}
         
          <div className="d-flex justify-content-between">
          <h3> Update Student </h3>
          <div>
          <button
            class="btn btn-outline-success mr-5 mt-3"
            onClick={() => {
              navigate("/student-list");
            }}
          >
            Student List
          </button>{" "}
        
          </div>
          </div>
          <form onSubmit={submitHandler}>
            <div class="row col-sm-12 col-xs-12 col-lg-12 mt-2 ">
              <div class="form-group col-sm-6 col-lg-3 ">
                <label for="amount">First Name</label>

                <input
                  type="text"
                  class="form-control"
                  id="first_name"
                  name="first_name"
                  value={rechargeData.first_name}
                  onChange={inputHandler}
                  placeholder="First Name"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3">
                <label for="amount">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="last_name"
                  name="last_name"
                  value={rechargeData.last_name}
                  onChange={inputHandler}
                  placeholder="Last Name"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3 col-12">
                <label>Phone</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  name="phone"
                  value={rechargeData.phone}
                  onChange={inputHandler}
                  placeholder="Phone"
                />
              </div>
              <div class="form-group col-sm-6 col-lg-3 ">
                <label>City</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  name="city"
                  value={rechargeData.city}
                  onChange={inputHandler}
                  placeholder="City"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3">
                <label>State</label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  name="state"
                  value={rechargeData.state}
                  onChange={inputHandler}
                  placeholder="State"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3 ">
                <label>Pincode</label>
                <input
                  type="text"
                  class="form-control"
                  id="pincode"
                  name="pincode"
                  value={rechargeData.pincode}
                  onChange={inputHandler}
                  placeholder="Pincode"
                />
              </div>
              <div class="form-group col-lg-3">
                <label>Address</label>
                <textarea
                  type="text"
                  class="form-control"
                  id="address"
                  name="address"
                  style={{ height: "10px" }}
                  value={rechargeData.address}
                  onChange={inputHandler}
                  placeholder="Address"
                />
              </div>

              <div class="form-group col-sm-6 col-lg-3 ">
                <label>Status</label>
                <br></br>
                <label>
                  <input
                    type="radio"
                    name="active"
                    value="1"
                    checked={rechargeData.active === "1"}
                    onChange={inputHandler}
                  />
                  Active
                </label>{" "}
                <label>
                  <input
                    type="radio"
                    name="active"
                    value="0"
                    checked={rechargeData.active === "0"}
                    onChange={inputHandler}
                  />
                  Deactive
                </label>
              </div>

              <div className="col-sm-6 col-lg-3">
                <label>Class</label>
                <select
                  id="class"
                  name="class"
                  value={rechargeData.class}
                  onChange={inputHandler}
                  className="form-select"
                  aria-label="      Default select example"
                >
                  <option value="">Select The Class</option>
                  {stuclass.map((item, index) => (
                    <option key={index} value={item.class}>
                      {item.class}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-6 col-lg-3">
                <label>Pattern</label>
                <select
                  id="pattern"x
                  name="pattern"
                  value={rechargeData.pattern}
                  onChange={inputHandler}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="">Select The Pattern</option>
                  {patern.map((item, index) => (
                    <option key={index} value={item.pattern}>
                      {item.patern}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                {isLoading && <Loader />}
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                <button
                  class="btn btn outine-success"
                  type="submit"
                  style={{ marginTop: "10px" }}
                  disabled={formfilled}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
}
