import React, { useState,useEffect} from "react";
import NotesList from "./NotesList";
import axios from "axios";
import { PostAPI, PostAPIWithImageUpload } from "../../../../services/Service";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
  

const classArray = [
  { class: "1st" },
  { class: "2nd" },
  { class: "3rd" },
  { class: "4th" },
  { class: "5th" },
  { class: "6th" },
  { class: "7th" },
  { class: "8th" },
  { class: "9th" },
  { class: "10th" },
  { class: "11th" },
  { class: "12th" },
  { class: "1st year" },
  { class: "2nd year" },
  { class: "3rd year" },
];
const paternArray = [{ patern: "CBSE" }, { patern: "M.P Board" }];

const subjectArray = [
  { sub: "Hindi" },
  { sub: "English" },
  { sub: "Maths" },
  { sub: "Physics" },
  { sub: "Chemistry" },
  { sub: "Bio" },
  { sub: "Account" },
];

const lessonArray = [
  { lesson: "1st" },
  { lesson: "2nd" },
  { lesson: "3rd" },
  { lesson: "4th" },
  { lesson: "5th" },
  { lesson: "6th" },
  { lesson: "7th" },
  { lesson: "8th" },
  { lesson: "9th" },
  { lesson: "10th" },
  { lesson: "11th" },
  { lesson: "12th" },
  { lesson: "13th" },
  { lesson: "14th" },
];

const NotesData = {
  subject: "",
  class: "",
  notes_title: "",
  notes_pattern: "",
  notes_description: "",
  notes_lession: "",
  id:"",
};

export default function UpdateNotes() {
  const { id } = useParams(); 
  const [YourId, setYourId]= useState(id);
  const [stuclass, setStuClass] = useState(classArray);
  const [patern, setPatern] = useState(paternArray);
  const [sub, setSub] = useState(subjectArray);
  const [lesson, setLesson] = useState(lessonArray);
  const [inputFormData, setInputFormData] = useState(NotesData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFormData({ ...inputFormData, [name]: value });
   
  };

  const getNotes = async () =>{
   debugger;
    const getData = await PostAPI("notes/notes_list_id",{id:YourId});
    console.log(getData.response_code);
    console.log(getData.id);
    if(getData.response_code=="200"){
        setInputFormData(getData.data);
    }
    else if(getData.response_code=="500"){
      toast.error(getData.message);
    }
  }
  
useEffect (() =>{
  debugger;
  getNotes();
},[]);

useEffect (() =>{
  getNotes();
},[YourId]);

  const handleSubmit = async (e) => {
   e.preventDefault();
   setIsLoading(true);
   
    const UpdateData = await PostAPI("notes/update_notes",
    inputFormData
      );
      if(UpdateData.response_code=="200"){
        toast.success(UpdateData.message);
        setIsLoading(false);
      }

      else if(UpdateData.response_code=="500"){
        toast.error(UpdateData.message);
        setIsLoading(false);
      }
    
  };

 

  return (
    <form onSubmit={handleSubmit}>
      <div className="content-wrapper">
      
        <main role="main" className="col-md-12 pt-3 px-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2"> Update Notes</h1>
            <button
            class="btn btn-outline-success float-right"
            onClick={() => {
              navigate("/notes");
            }}
          >
            Notes List
          </button>{" "}
          </div>
           
          <div className="row">
            <div className="col-md-6 pt-3 px-4">
              <select
                id="class"
                name="class"
                value={inputFormData.class}
                onChange={handleChange}
                className="form-select"
                aria-label="  Default select example"
              >
                <option value="">Select The Class</option>
                {stuclass.map((item, index) => (
                  <option key={index} value={item.class}>
                    {item.class}
                  </option>
                ))}
              </select>

              <div style={{ color: "red" }} className="error">
                {errors.class}
              </div>
            </div>

            <div className="col-md-6 pt-3 px-4">
              <select
                id="notes_pattern"
                name="notes_pattern"
                value={inputFormData.notes_pattern}
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="">Select The Pattern</option>
                {patern.map((item, index) => (
                  <option key={index} value={item.pattern}>
                    {item.patern}
                  </option>
                ))}
              </select>
              <div style={{ color: "red" }} className="error">
                {errors.patern}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 pt-3 px-4">
              <select
                id="subject"
                name="subject"
                value={inputFormData.subject}
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="">Select The Subject</option>
                {sub.map((item, index) => (
                  <option key={index} value={item.sub}>
                    {item.sub}
                  </option>
                ))}
              </select>
              <div style={{ color: "red" }} className="error">
                {errors.subject}
              </div>
            </div>

            <div className="col-md-6 pt-3 px-4">
              <select
                id="notes_lession"
                name="notes_lession"
                value={inputFormData.notes_lession}
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="">Select The Lesson</option>
                {lesson.map((item, index) => (
                  <option key={index} value={item.lesson}>
                    {item.lesson}
                  </option>
                ))}
              </select>
              <div style={{ color: "red" }} className="error">
                {errors.lesson}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 pt-3 px-4">
              <input
                id="notes_title"
                name="notes_title"
                value={inputFormData.notes_title}
                onChange={handleChange}
                type="text"
                className="form-control"
                placeholder="Title"
                aria-label="Server"
              />
              <div style={{ color: "red" }} className="error">
                {errors.notes_title}
              </div>
            </div>

          
          </div>

          <div className="row">
            <div className="col-lg-11  my-3 mx-2 ">
              <textarea
                className="form-control"
                id="description"
                name="notes_description"
                value={inputFormData.notes_description}
                onChange={handleChange}
                rows="3"
                style={{ marginLeft: "5px" }}
                placeholder="Enter description"
              ></textarea>
              {errors.notes_description && (
                <div style={{ color: "red" }} className="error">
                  {errors.notes_description}
                </div>
              )}
            </div>
            <div className="mx-3 my-2">
              <button
                type="submit"
                className="btn btn-outline-success"
              
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </main>
      </div>
    </form>
  );
}
