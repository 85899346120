import React,{Suspense,lazy} from 'react';
import { useEffect,useState } from 'react';

import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SocketProvider} from "./SocketContext";

import {
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams,Navigate
} from "react-router-dom";


import Login from './component/auth/Login';
//import About from './component/pages/About';
import Protected from './component/Protected';

//import UserList from './component/pages/admin/UserList';

//Wallet Module
import Wallethistory from './component/pages/admin/wallet/Wallethistory';
import WalletRequst from './component/pages/admin/wallet/WalletRequest';

import HomeIndex from './component/pages/frontend/index';
import Home from './component/pages/frontend/Home';


//User Module 
import CreateNewUser from './component/pages/admin/user/CreateNewUser';
import Changepswd from './component/pages/admin/user/Changepswd';

import Support from './component/pages/admin/support/Support';
import Dashboard from "./component/pages/admin/Dashboard";

import About from './component/pages/frontend/About';
import ContactUs from './component/pages/frontend/ContactUs';
import Service from './component/pages/frontend/Service';
import UserList from './component/pages/admin/user/UserList';
import PrivacyPolicy from './component/pages/frontend/PrivacyPolicy';
import Registration from './component/auth/registration/Registration';

import ForgotPassword from './component/auth/forgotPassword/ForgotPassword';
import WalletRechargehistory from './component/pages/admin/wallet/WalletRechargeHistory';
import Notes from './component/pages/admin/notes/Notes';
import Videos from './component/pages/admin/videos/Videos';

import StudentManagement from './component/pages/admin/StudentManagement/StudentManagement'
import StudentList from './component/pages/admin/StudentManagement/StudentList';
import Enquiry from './component/pages/admin/Enquiry/Enquiry';
import Video from './component/pages/admin/videos/Videos'
import StudentVideo from './component/pages/admin/videos/StudentVideo';
import  UpdateNotes from './component/pages/admin/notes/UpdateNotes';
import StudentNotes from './component/pages/admin/notes/StudentNotes'
import UpdateVideo from './component/pages/admin/videos/UpdateVideo';
import UpdateStudent from './component/pages/admin/StudentManagement/UpdateStudent';
import Profile from './component/pages/admin/profile/Profile';


function App() {
  const [isLoggedIn, setisLoggedIn] = useState(null);

  return (
    <SocketProvider>
    <div className="" id='root'>

      <Routes>

        <Route path="/" element={<HomeIndex/>} >
        <Route path="/" element={<Login/>} />
          <Route path='forgotPassword' element={<ForgotPassword/>}/>
          <Route path="login" element={<Login/>} />
          
          <Route path='registration' element={<Registration/>} />
          <Route path="home" element={<Home/>} />
          <Route path="about" element={<About/>} />
          <Route path="contact-us" element={<ContactUs/>} />
          <Route path="service" element={<Service/>} />
          <Route path="privacy-policy" element={<PrivacyPolicy/>} />
       
        </Route>
 
        <Route element={<Protected UserGroup="Admin|Distributor|Master Distributor"/>}>
          <Route path="userlist" element={<UserList/>} />
        </Route>
          
        <Route element={<Protected UserGroup="Admin|Retailer|Distributor|Master Distributor"/>}>
          <Route path="add-user" element={<CreateNewUser/>} />  
        </Route>

        <Route element={<Protected UserGroup="Teacher"/>}>
          <Route path="dashboard" element={<Dashboard/>} ></Route>
          <Route path="history" element={<Wallethistory/>} />
          <Route path="changepassword" element={<Changepswd/>} />
          <Route path="support" element={<Support/>} />
          <Route path="wallet-request" element={<WalletRequst/>} />
          <Route path="wallet-request/history" element={<WalletRechargehistory/>} />
          <Route path="notes" element={<Notes/>} />
          <Route path="videos" element={<Videos/>}/>
          
        </Route>




        <Route element={<Protected UserGroup="Admin|Retailer|Distributor|Master Distributor"/>}>
          <Route path="/student-management" element={<StudentManagement/>} />  
        </Route>

        <Route element={<Protected UserGroup="Admin|Retailer|Distributor|Master Distributor"/>}>
          <Route path="/student-list" element={<StudentList/>} />  
        </Route>

        <Route element={<Protected UserGroup="Admin|Retailer|Distributor|Master Distributor"/>}>
          <Route path="/enquiry" element={<Enquiry/>} />  
        </Route>

        <Route element={<Protected UserGroup="Teacher"/>}>
          <Route path="/video" element={<Video/>} />  
        </Route>


        <Route element={<Protected UserGroup="Admin|Retailer|Distributor|Master Distributor"/>}>
          <Route path="/update-notes/:id" element={<UpdateNotes/>} />  
        </Route>

        <Route element={<Protected UserGroup="Admin|Retailer|Distributor|Master Distributor"/>}>
          <Route path="/student-update/:id" element={<UpdateStudent/>} />  
        </Route>

        <Route element={<Protected UserGroup="Admin|Retailer|Distributor|Master Distributor"/>}>
          <Route path="/update-video/:id" element={<UpdateVideo/>} />  
        </Route>


        <Route element={<Protected UserGroup="Student"/>}>
          <Route path="/student-video" element={<StudentVideo/>} />  
        </Route>

        <Route element={<Protected UserGroup="Student"/>}>
          <Route path="/study-notes" element={<StudentNotes/>} />  
        </Route>

        <Route element={<Protected UserGroup="Student"/>}>
          <Route path="/profile" element={<Profile/>} />  
        </Route>



       
{/* 
        <Route element={<Protected UserGroup="Student"/>}>
          <Route path="/support" element={<Support/>} />  
        </Route> */}


         
      </Routes>  	        
        <ToastContainer />
    </div>
    </SocketProvider>
  );
}

export default App;
