import React from "react";
import { PostAPI } from "../../../../services/Service";
import Pagination from "../../Pagination";
import { PAGINATION_PER_PAGE } from "../../../../utils/Constants";
import { toast } from "react-toastify";
import Loader from "../../../assest/UI/Loader";
import { API_BASE_URL } from "./../../../../utils/config";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VideoList = () => {
  const [items_perpage, setItems_perpage] = useState(PAGINATION_PER_PAGE);
  const [current_page, setCurrent_page] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();

  const getVideoList = async () => {
    setIsLoading(true);
    const videoListData = await PostAPI("video/video_list", {
      items_perpage: items_perpage,
      current_page: current_page,
    });

    if (videoListData.response_code === 200) {
      setVideoList(videoListData.data.result);
      setTotalPage(videoListData.data.total);
      setIsLoading(false);
    } else {
      toast.error(videoListData.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getVideoList();
  }, []);

  useEffect(() => {
    getVideoList();
    setRender(false);
  }, [current_page,render]);

  const pagechangeHandler = (offset) => {
    setCurrent_page(offset);
  };

  // delete Video

  const deleteVideo = async (e, id) => {
    e.preventDefault();
    const deleteId = id;
    const confirmVar = window.confirm("Are you sure you want to delete?");
    if (confirmVar) {
      const deleteVideoData = await PostAPI("video/delete_videonotes", {
        id: deleteId,
      });

      if (deleteVideoData.response_code == "200") {
        toast.success("Data deleted Successfully");
        setRender((prevRender) => !prevRender);
      } else if (deleteVideoData.response_code == "500") {
        toast.error("Data not deleted");
      }
      setRender(true);
    }
  };
  useEffect(() => {
    getVideoList();
  }, [render]);

  return (
    <div>
      <h3 style={{ margin: "10px" }}>Video List</h3>
      <table
        style={{ margin: "13px" }}
        className="table table-striped table-bordered"
      >
        <thead>
          <tr>
            <th>#</th>
            <td className="text-dark">Class Name</td>
            <td className="text-dark">Pattern</td>
            <td className="text-dark">Subject</td>
            <td style={{ width: "300px" }} className="text-dark">
              Title
            </td>
            <td className="text-dark">Video</td>
            <td className="text-dark">Action</td>
          </tr>
        </thead>
        <tbody>
          {videoList.length === 0 && (
            <tr>
              <td colSpan="5">No Record Found</td>
            </tr>
          )}
          {videoList.map((value, key) => (
            <tr>
              <td>{value.id}</td>
              <td>{value.class}</td>
              <td>{value.pattern}</td>
              <td>{value.subject}</td>
              <td>{value.title}</td>
              <td>
              <a href={`${API_BASE_URL}/video/${value.video}`} target="_blank">
                <video
                  src={`${API_BASE_URL}/video/${value.video}`}
                  type="video/mp4"
              
                  
                  style={{ width: "50px", height: "50px" }}
                />
                </a>
              </td>
              <td>
                <i
                  onClick={(e) => deleteVideo(e, value.id)}
                  class="bi bi-trash-fill"
                ></i>

                <i
                  onClick={() => {
                    navigate(`/update-video/${value.id}`);
                  }}
                  class="bi bi-pencil-square ml-5"
                >
                  {" "}
                </i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <Loader />}
      {totalPages > 0 && (
        <Pagination
          itemsPerPage={items_perpage}
          totalPage={totalPages}
          pagechangeHandler={pagechangeHandler}
        />
      )}
    </div>
  );
};

export default VideoList;
