import React from 'react'

const data = [
  {
    name: 'Nikita', 
    email: 'nikita123@gmail.com',
    mobileNo :"+91121232434",
    Message:"every mistake is a stepping stone to success"

   },
   {
    name: 'Aradhya', 
    email: 'nikita123@gmail.com',
    mobileNo :"+91121232434",
    Message:"every mistake is a stepping stone to success"

   },
   {
    name: 'prisha', 
    email: 'nikita123@gmail.com',
    mobileNo :"+91121232434",
    Message:"every mistake is a stepping stone to success"

   },
   {
    name: 'Mohit', 
    email: 'nikita123@gmail.com',
    mobileNo :"+91121232434",
    Message:"every mistake is a stepping stone to success"

   },
   {
    name: 'Naman', 
    email: 'nikita123@gmail.com',
    mobileNo :"+91121232434",
    Message:"every mistake is a stepping stone to success"

   },
   {
    name: 'Raj', 
    email: 'nikita123@gmail.com',
    mobileNo :"+91121232434",
    Message:"every mistake is a stepping stone to success"

   },
];

const Enquiry = () => {
  return (
    <div className='content-wrapper'>
      <h2 className='ml-4'>Student Enquiry </h2>
      <div class="table-responsive mx-4 py-4">
      <table class="table table-hover table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile No.</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
        {data.map((item, index ) => (
              <tr key={index} className={index % 2 === 0 ? "table-success" : ""}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.mobileNo}</td>
                <td>{item.Message}</td>
              </tr>
            ))}
      
        </tbody>
      </table>
    </div>
      
    </div>
  )
}

export default Enquiry;










  // const [formData, setFormData] = useState({
  //   studentName: "",
  //   email: "",
  //   mobileNo: "",
  //   message: "",
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   console.log(formData);

  //   setFormData({
  //     studentName: "",
  //     email: "",
  //     mobileNo: "",
  //     message: "",
  //   });
  // };

  // return (
  //   <>
  //    <div className="content-wrapper "
  //    >
  //     <h2 style={{marginLeft:"60px"}}
  //      >Student Enquiry Form</h2>
  //     <form onSubmit={handleSubmit}>
  //       <div className="form-group ml-5 mt-4">
  //         <label className="ml-3" htmlFor="studentName">Student Name:</label>
  //         <input
  //           type="text"
  //           className="form-control ml-3"
  //           id="studentName"
  //           name="studentName"
  //           style={{width:"600px"}}
  //           value={formData.studentName}
  //           onChange={handleChange}

  //         />
  //       </div>
  //       <div className="form-group ml-5 mt-4">
  //         <label className="ml-3" htmlFor="email">Email:</label>
  //         <input
  //           type="email"
  //           className="form-control ml-3"
  //           id="email"
  //           name="email"
  //           style={{width:"600px"}}
  //           value={formData.email}
  //           onChange={handleChange}
        
  //         />
  //       </div>
  //       <div className="form-group ml-5 mt-4">
  //         <label className="ml-3" htmlFor="mobileNo">Mobile No:</label>
  //         <input
  //           type="tel"
  //           className="form-control ml-3"
  //           id="mobileNo"
  //           name="mobileNo"
  //           style={{width:"600px"}}
  //           value={formData.mobileNo}
  //           onChange={handleChange}
    
  //         />
  //       </div>
  //       <div className="form-group ml-5 mt-4">
  //         <label className="ml-3" htmlFor="message">Message:</label>
  //         <textarea
  //           className="form-control ml-3"
  //           id="message"
  //           name="message"
  //           value={formData.message}
  //           onChange={handleChange}
  //           style={{width:"600px"}}
            
  //         ></textarea>
  //       </div>
  //       <div className="form-group row">
  //         <div className="col-sm-10 offset-sm-3 ">
  //           <button type="submit" className="btn btn-primary">Submit</button>
  //         </div>
  //       </div>
  //     </form>
      
  //   </div>
  //   </>
 






