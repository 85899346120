import React from "react";
import Banner from "./Banner";


/**
 * @author
 * @function Home
 **/

const Home = (props) => {
  return (
    <>
      
    </>
  );
};

export default Home;
