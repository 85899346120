import React, { useEffect, useState } from "react";
import { PostAPI } from "../../../../services/Service";
import { API_BASE_URL } from "./../../../../utils/config";
import Loader from "../../../assest/UI/Loader";
//Default Img

const DEFAULT_VIDEO_URL =
  "https://videos.pexels.com/video-files/852237/852237-hd_1920_1080_30fps.mp4";

const StudentNotes = () => {
  const [video, setVideo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchVideos = async () => {
      setIsLoading(true)
    const response = await PostAPI("video/video_list");
    setVideo(response.data.result);
    setIsLoading(false)
  };
  useEffect(() => {
    fetchVideos();
  }, []);

  const openVideoInNewWindow = (videoURL) => {
    const newWindow = window.open(videoURL, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  return (
    <div className="content-wrapper px-4">
      <h2>Learning Videos</h2>
      <div className="row">
      {isLoading && 
                <Loader/>
              }
        {video.map((items) => {
          return (
            <div className="col-md-4 " key={items.id}>
              <div
                className="card mt-5 p-4" 
                // style={{ width: "20rem", height: "18rem" }}
              >
                {/* <video
                  className="card-img-top"
                  controls
                  style={{ width: "100%", height: "100%" }}
                >
                  <source
                    src={
                      items.video
                        ? `${API_BASE_URL}/video/${items.video}`
                        : DEFAULT_VIDEO_URL
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video> */}

                <button
                  className="btn btn-link p-0"
                  onClick={() =>
                    openVideoInNewWindow(
                      items.video
                        ? `${API_BASE_URL}/video/${items.video}`
                        : DEFAULT_VIDEO_URL
                    )
                  }
                >
                  <video
                    className="card-img-top"
                    controls
                    style={{ width: "100%", height: "50%" }}
                  >
                    <source
                      src={
                        items.video
                          ? `${API_BASE_URL}/video/${items.video}`
                          : DEFAULT_VIDEO_URL
                      }
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </button>
                <div class="card-body text-center">
                  <h5 className="card-title fw-bold "
                  //  style={{ marginLeft:"7.5rem "}}
                   >
                    {/* {items.title} */}
                     Title
                    </h5>
                  <p class="card-text fw-bold " 
                  style={{ marginBottom: "10px" }}
                  >
                    {/* {items.description} */}
                      Description
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StudentNotes;
