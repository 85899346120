import isEmpty from "is-empty";
import {NavLink,Link} from "react-router-dom";
import {useState,useContext,createContext,useEffect} from "react";
import './Styles.css';


function Footer(props) {
 

  return (
    <div class="footer">
    
     
    
    </div>   
  );
}

export default Footer;
